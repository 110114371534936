































































































































































import { Vue, Component } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { StringHelper } from '@/utils/helpers/string-helper';
import dayjs from '@/plugins/dayjs';
import CrmAppBar from '@/components/crm/app-bar.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import Tooltip from '@/components/tooltip.vue';
import { ISimpleTableColumn } from '@/interfaces/simple-table-column.interface';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import { IStatisticsFilter } from '@/interfaces/crm/statistics-filter.interface';
import RouterService from '@/services/router.service';
import ContactService from '@/services/crm/contact.service';
import ActivityService from '@/services/crm/activity.service';
import ProcessService from '@/services/crm/process.service';
import StatisticsService from '@/services/crm/statistics.service';
import ClientService from '@/services/crm/client.service';
import ProspectService from '@/services/crm/prospect.service';
import ActivityEventModel from '@/models/crm/activity-event.model';
import ProcessFlowModel from '@/models/crm/process-flow.model';
import { PaginationModel } from '@/models/pagination.model';
import ProcessModel from '@/models/crm/process.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import CardDataList from '@/components/card-data-list.vue';
import AsyncCardDataList from '@/components/crm/async-card-data-list.vue';
import MultipleCardsPanel from '@/components/multiple-cards-panel.vue';
import ContentDialog from '@/components/content-dialog.vue';
import CrmNewKanbanViewer from '@/components/crm/new-kanban-viewer.vue';
import CrmActivityCalendarEventForm from '@/components/crm/activity-calendar-event-form.vue';
import { StatisticsVisualizationEnum } from '@/enums/crm/statistics-visualization.enum';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import SettingsService from '@/services/crm/settings.service';
import SettingsModel from '@/models/crm/settings.model';

interface IEvent {
  id: number;
  description: string;
  date: string;
  contact: string;
  cnpj: string;
  tipo: string;
}

interface ICardDetails {
  key: string;
  unit: string;
  hint?: string;
  value: number;
  loading: boolean;
  secondaryValue?: number;
  secondaryPrefix?: string;
  secondarySuffix?: string;
  detailsCallback?: CallableFunction;
}

@Component({
  components: {
    CrmAppBar,
    Tooltip,
    CardDataList,
    AsyncCardDataList,
    ContentDialog,
    CrmNewKanbanViewer,
    CrmActivityCalendarEventForm,
    MultipleCardsPanel,
  },
})
export default class CrmAttendantPanel extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmProcessService)
  private processService!: ProcessService;

  @inject(InjectionIdEnum.CrmStatisticsService)
  private statisticsService!: StatisticsService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  private settings!: SettingsModel;

  async created(): Promise<void> {
    await this.loadUserContactInfo();
    this.settings = await this.settingsService.getSettings();
    this.processFlowOptions = await this.processService.getFlows();
    this.onUpdateData();
  }

  processFlowOptions: ProcessFlowModel[] = [];

  screenPath = 'crm.view.attendantPanel';

  dialogConfig: IKeyValue<IDialogConfig> = {
    activityView: {
      show: false,
      id: null,
      cnpj: null,
      cliente: null,
      tipo: null,
    },
    processKanban: {
      show: false,
      idFluxo: null,
      nomeFluxo: null,
      process: null,
      idFila: null,
    },
  };

  userContactInfo: UserContactInfo | null = null;

  attendancesMonth = 0;

  attendancesClosedMonth = 0;

  openChatsCount = 0;

  averageAttendanceTime = 0;

  newClientsMonth = 0;

  convertedProspectsMonth = 0;

  contactedProspectsMonth = 0;

  delayedEventsDataListItems: PaginationModel<ActivityEventModel> = new PaginationModel<ActivityEventModel>();

  eventDataListItems: PaginationModel<ActivityEventModel> = new PaginationModel<ActivityEventModel>();

  processDataListItems: PaginationModel<ProcessModel> = new PaginationModel<ProcessModel>();

  eventDataListColumns: ISimpleTableColumn[] = [
    {
      name: `${this.$t('crm.view.attendantPanel.headers.schedule')}`,
      prop: 'titulo',
      width: '14vw',
    },
    {
      name: `${this.$t('crm.view.attendantPanel.headers.client')}`,
      prop: 'nome',
      width: '12vw',
    },
    {
      name: `${this.$t('crm.view.attendantPanel.headers.dateAndHour')}`,
      prop: 'dataHoraInicio',
    },
    {
      name: `${this.$t('crm.view.attendantPanel.headers.historyType')}`,
      prop: 'tipoHistorico',
      width: '12vw',
    },
    {
      name: `${this.$t('crm.view.attendantPanel.headers.status')}`,
      prop: 'situacao',
    },
  ];

  processDataListColumns: ISimpleTableColumn[] = [
    {
      name: `${this.$t('crm.view.attendantPanel.headers.name')}`,
      prop: 'fluxo',
      width: '14vw',
    },
    {
      name: `${this.$t('crm.view.attendantPanel.headers.client')}`,
      prop: 'cliente',
      width: '14vw',
    },
    {
      name: `${this.$t('crm.view.attendantPanel.headers.lastStep')}`,
      prop: 'etapa',
    },
    {
      name: `${this.$t('crm.view.attendantPanel.headers.dateAndHour')}`,
      prop: 'dataString',
    },
    {
      name: `${this.$t('crm.view.attendantPanel.headers.dateLimit')}`,
      prop: 'dataLimiteEtapa',
    },
  ];

  get initials(): string {
    if (this.userContactInfo) {
      return StringHelper.extractInitialsName(this.userContactInfo.nome).toUpperCase();
    }
    return '';
  }

  get processKanbanDialogTitle(): string {
    return `${this.$t('crm.view.dashboard.dialog.processKanban.title',
      { flow: this.dialogConfig.processKanban.nomeFluxo })}`;
  }

  async loadUserContactInfo(): Promise<void> {
    try {
      this.userContactInfo = await this.contactService.getLoggedUserContactInfo();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  async loadDelayedEventsDataListItems(offset = 10): Promise<void> {
    const finalDate = dayjs().format('YYYY-MM-DD hh:mm:ss');
    this.delayedEventsDataListItems = await this.activityService.getCalendarEventsByAttendant(
      false,
      true,
      dayjs('2000-01-01').unix(),
      dayjs(finalDate).unix(),
      offset,
    );
    const filteredByHour = this.delayedEventsDataListItems.results
      .filter((e) => dayjs(e.dataHoraFim).isBefore(dayjs()));

    this.delayedEventsDataListItems = {
      ...this.delayedEventsDataListItems,
      results: filteredByHour,
      total: filteredByHour.length,
    };

    if (this.delayedEventsDataListItems.results) {
      this.delayedEventsDataListItems.results = this.delayedEventsDataListItems.results.sort((a, b) => {
        const dataA = new Date(a.dataHoraInicio);
        const dataB = new Date(b.dataHoraInicio);
        return +dataB - +dataA;
      });
    }
  }

  async loadEventDataListItems(offset = 10): Promise<void> {
    this.eventDataListItems = await this.activityService.getCalendarEventsByAttendant(
      false,
      true,
      dayjs()
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .unix(),
      dayjs()
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .unix(),
      offset,
    );
    if (this.eventDataListItems.results) {
      this.eventDataListItems.results = this.eventDataListItems.results.sort((a, b) => {
        const dataA = new Date(a.dataHoraInicio);
        const dataB = new Date(b.dataHoraInicio);
        return +dataA - +dataB;
      });
    }
  }

  async loadProcessListItems(offset = 10): Promise<void> {
    this.processDataListItems = await this.processService.getAttendantProcesses(offset);
    this.processDataListItems.results = this.processDataListItems.results.sort((a, b) => {
      let dataA!: Date;
      let dataB!: Date;

      if (a.dataLimiteEtapa) {
        dataA = new Date(a.dataLimiteEtapa);
      } else {
        dataA = new Date(a.dataString);
      }
      if (b.dataLimiteEtapa) {
        dataB = new Date(b.dataLimiteEtapa);
      } else {
        dataB = new Date(b.dataString);
      }
      return +dataA - +dataB;
    });
  }

  async loadAllTimeInformation(): Promise<void> {
    if (this.userContactInfo) {
      const filter: IStatisticsFilter = {
        attendant: [this.userContactInfo.id],
        periodStart: new Date('2000-01-01'),
        periodEnd: new Date(),
        visualization: StatisticsVisualizationEnum.Quantity,
        openOnly: true,
      };
      this.statisticsService.getTotalOpenConversationsPerAttendant(filter).then((res) => {
        this.openChatsCount = res[0] ? Number(res[0].total) : 0;
      });
    }
  }

  async loadMonthlyInformation(): Promise<void> {
    if (this.userContactInfo) {
      const filter: IStatisticsFilter = {
        attendant: [this.userContactInfo.id],
        periodStart: new Date(dayjs().subtract(dayjs().date() - 1, 'day').format('YYYY-MM-DD')),
        periodEnd: new Date(dayjs().endOf('month').format('YYYY-MM-DD 23:59:59')),
        visualization: StatisticsVisualizationEnum.Quantity,
        closedOnly: false,
      };
      this.statisticsService.getTotalAttendancesPerAttendant(filter).then((res) => {
        this.attendancesMonth = res[0] ? Number(res[0].total) : 0;
      });
      filter.closedOnly = true;
      this.statisticsService.getTotalAttendancesPerAttendant(filter).then((res) => {
        this.attendancesClosedMonth = res[0] ? Number(res[0].total) : 0;
      });
      filter.closedOnly = false;
      this.statisticsService.getAverageAttendanceTimePerAttendant(filter).then((res) => {
        this.averageAttendanceTime = res[0] ? Number(res[0].total) : 0;
      });
      this.statisticsService.getOpeningOfNewClientsPerAttendant(filter).then((res) => {
        this.newClientsMonth = res[0] ? Number(res[0].total) : 0;
      });
      this.statisticsService.getConvertedClientsPerAttendant(filter).then((res) => {
        this.convertedProspectsMonth = res[0] ? Number(res[0].total) : 0;
      });
      this.statisticsService.getContactedClientsPerAttendant(filter).then((res) => {
        this.contactedProspectsMonth = res[0] ? Number(res[0].total) : 0;
      });
    }
  }

  get chatResumeDataListItems(): ICardDetails[] {
    return [{
      key: 'chatResume1',
      unit: this.$t('global.units.minutes').toString(),
      hint: this.$t('crm.view.attendantPanel.hints.averageWppTime').toString(),
      value: this.averageAttendanceTime,
      loading: false,
    },
    {
      key: 'chatResume2',
      unit: this.$t('global.units.quantity').toString(),
      value: this.openChatsCount,
      loading: false,
      detailsCallback: () => {
        if (this.settings.flagUtilizaWhatsapp) {
          this.routerService.navigate({ name: 'CrmHome', params: { previousRoute: 'CrmAttendantPanel' } });
        } else {
          this.$notify.warn(this.$t('crm.view.attendantPanel.noWhatsapp'));
        }
      },
    }];
  }

  get monthAttendancesDataListItems(): ICardDetails[] {
    return [{
      key: 'monthAttendances1',
      unit: this.$t('global.units.quantity').toString(),
      value: this.attendancesMonth,
      loading: false,
    },
    {
      key: 'monthAttendances2',
      unit: this.$t('global.units.quantity').toString(),
      value: this.attendancesClosedMonth,
      loading: false,
    }];
  }

  get monthResultsDataListItems(): ICardDetails[] {
    return [{
      key: 'monthResults1',
      unit: this.$t('global.units.clients').toString(),
      hint: this.$t('crm.view.attendantPanel.hints.newClients').toString(),
      value: this.newClientsMonth,
      loading: false,
    },
    {
      key: 'monthResults2',
      unit: this.$t('global.units.clients').toString(),
      hint: this.$t('crm.view.attendantPanel.hints.conversion').toString(),
      value: this.convertedProspectsMonth,
      secondaryValue: this.convertedProspectsMonth && this.convertedProspectsMonth === 0 ? 0
        : Math.round((this.convertedProspectsMonth / this.contactedProspectsMonth) * 100),
      secondarySuffix: '%',
      loading: false,
    }];
  }

  async onLoadMore(): Promise<void> {
    const loader = this.$loading.show();
    try {
      const n = (this.delayedEventsDataListItems.results.length || 0) + 10;
      const tasks = [this.loadDelayedEventsDataListItems(n)];

      await Promise.all(tasks);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onLoadMoreDayAttendances(): Promise<void> {
    try {
      const n = (this.eventDataListItems.results.length || 0) + 10;
      const tasks = [this.loadEventDataListItems(n)];

      await Promise.all(tasks);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  async onLoadMoreProcesses(): Promise<void> {
    const loader = this.$loading.show();
    try {
      const n = (this.processDataListItems.results.length || 0) + 10;
      const tasks = [this.loadProcessListItems(n)];

      await Promise.all(tasks);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async onUpdateData(): Promise<void> {
    const loader = this.$loading.show();
    try {
      const tasks = [this.loadDelayedEventsDataListItems(),
        this.loadEventDataListItems(),
        this.loadProcessListItems(),
        this.loadAllTimeInformation(),
        this.loadMonthlyInformation(),
      ];

      await Promise.all(tasks);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  onAddEvent(): void {
    if (this.settings.flagUtilizaAgenda) {
      this.dialogConfig.activityView.id = null;
      this.dialogConfig.activityView.cnpj = '';
      this.dialogConfig.activityView.show = true;
    } else {
      this.$notify.warn(this.$t('crm.view.attendantPanel.noAttendances'));
    }
  }

  async onEditEvent(item: IEvent): Promise<void> {
    if (this.settings.flagUtilizaAgenda) {
      this.dialogConfig.activityView.id = item.id;
      this.dialogConfig.activityView.tipo = item.tipo === ClientTypeEnum.Client
        ? ClientTypeEnum.Client : ClientTypeEnum.Prospect;
      this.dialogConfig.activityView.cnpj = item.cnpj;
      if (item.tipo === ClientTypeEnum.Client) {
        this.dialogConfig.activityView.cliente = await
        this.clientService.getIgsClient(item.cnpj);
      }
      this.dialogConfig.activityView.show = true;
    }
  }

  onAddProcess(flow: ProcessFlowModel): void {
    this.dialogConfig.processKanban.idFluxo = flow.id;
    this.dialogConfig.processKanban.nomeFluxo = flow.nome;
    this.dialogConfig.processKanban.clientId = '';
    this.dialogConfig.processKanban.clientType = '';
    this.dialogConfig.processKanban.show = true;
  }

  async onEditProcess(item: ProcessModel): Promise<void> {
    if (item.temFluxoEtapaUsuarioEtapaAtual || item.temFluxoEtapaUsuario) {
      this.dialogConfig.processKanban.idFluxo = item.idFluxo;
      this.dialogConfig.processKanban.nomeFluxo = item.fluxo;
      this.dialogConfig.processKanban.process = {
        id: item.idProcesso,
        idFluxo: item.idFluxo,
        description: item.descricao,
        step: item.etapa,
        idFila: item.idFila,
        contact: item.cliente,
      };
      this.dialogConfig.processKanban.idFila = item.idFila;
      this.dialogConfig.processKanban.clientId = item.cnpj;
      this.dialogConfig.processKanban.clientType = item.tipoCliente;
      this.dialogConfig.processKanban.show = true;
    } else {
      this.$notify.error(this.$t('crm.view.attendantPanel.detailNotAuthorized'));
    }
  }

  onAfterActivityViewClose(): void {
    this.dialogConfig.activityView.show = false;
    this.loadDelayedEventsDataListItems();
    this.loadMonthlyInformation();
    this.loadEventDataListItems();
  }

  onAfterKanbanClose(): void {
    this.dialogConfig.processKanban.show = false;
    this.dialogConfig.processKanban.process = null;
    this.loadProcessListItems();
  }
}
